<template>
    <div id="info">
        <el-row>
            <el-col>
                <div class="grid-content bg-purple-dark"></div>
                <el-card class="box-card">
                    <el-row :gutter="20">
                        <el-select v-model="input" filterable clearable placeholder="Select Tissue">
                            <el-option v-for="item in TissueFilter" :key="item.value" :label="item.label"
                                :value="item.value"></el-option>
                        </el-select>
                    </el-row>
                    <el-table :data="
                    	data_list
                    		.filter(
                    			(data) =>
                    				!input ||
                    				data.Tissue.toLowerCase().includes(
                    					input.toLowerCase()
                    				) ||
                    				data.Dataset.toLowerCase().includes(
                    					input.toLowerCase()
                    				)
                    		)
                    		.slice(
                    			(currentPage - 1) * pageSize,
                    			currentPage * pageSize
                    		)
                    " stripe border :default-sort="{
                    	prop: 'Tissue',
                    	order: 'ascending',
                    }" @sort-change="sortChange" :row-class-name="tableRowClassName" @filter-change="filterChange"
                        @row-click="onRowClick">
                        <template slot="empty">
                            <div class="noData">No Results :(</div>
                        </template>

                        <el-table-column prop="Tissue" label="Tissue" align="center" min-width="8%" sortable="custom"
                            column-key="Tissue"></el-table-column>

                        <el-table-column prop="Dataset" label="Dataset" align="center" min-width="20%"
                            sortable="custom">
                            <template slot-scope="scope">
                                <el-popover placement="right" width="200" trigger="hover">
                                    <span>
                                        <img :src="
                                        	require(`@/assets/pictures/cluster/${scope.row.Dataset}_cluster.png`)
                                        " style="
													max-height: 500px;
													max-width: 500px;
												" />
                                        <img :src="
                                        	require(`@/assets/pictures/assign/${scope.row.Dataset}_assign.png`)
                                        " style="
													max-height: 500px;
													max-width: 500px;
												" />
                                    </span>
                                    <div slot="reference">{{ scope.row.Dataset }}</div>
                                </el-popover>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Year" label="Year" align="center" min-width="8%" sortable="custom">
                        </el-table-column>
                        <el-table-column prop="PMID" label="PMID" align="center" min-width="8%" sortable="custom">
                            <template slot-scope="scope">
                                <a class="doclink" :href="scope.row.PMID" target="_blank">{{ scope.row.PMID }}</a>
                            </template>
                        </el-table-column>
                        <el-table-column prop="GSE" label="Source" align="center" min-width="10%" sortable="custom">
                            <template slot-scope="scope">
                                <a :href="scope.row.GSE" target="_blank" class="doclink">{{ scope.row.GSE }}</a>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Stage" label="Stage" align="center" min-width="7%" sortable="custom">
                        </el-table-column>
                        <el-table-column prop="Platform" label="Platform" align="center" min-width="10%"
                            sortable="custom"></el-table-column>
                        <el-table-column prop="Technology" label="Technology" align="center" min-width="11%"
                            sortable="custom"></el-table-column>
                        <el-table-column prop="CellNumber" label="CellNumber" align="center" min-width="11%"
                            sortable="custom"></el-table-column>
                        <el-table-column prop="Status" label="Status" align="center" min-width="7%" sortable="custom">
                        </el-table-column>
                    </el-table>

                    <div class="block" style="text-align: center">
                        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page.sync="currentPage" :page-size="10" layout="prev, pager, next, jumper" :total="
                            	data_list.filter(
                            		(data) =>
                            			!input ||
                            			data.Tissue.includes(input)
                            	).length
                            "></el-pagination>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
    <br />
    <br />
    <h2>Statistics</h2>
    <br />
    <br />
    <div id="statistics">
        <div style="width: 32">
            <img src="@/assets/summary_bar_merge.png" alt style="height: auto; max-width: 100%" />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import data_json from "@/assets/data_1011.json"; // 引用
import TissueFilter from "@/assets/alltissue1.json"; // 引用
import { getHeatmap } from "@/api/getGene.ts";

export default {
    name: "info",

    data() {
        return {
            itemList: [],
            data_list: data_json,
            res: "",
            input: "",
            input_tissue: "",
            hover: false,
            DatasetName: "",
            currentPage: 1,
            pageSize: 10,
            isRouterAlive: true,
            // options: allts,
            tissuename: "",
            inputGene: "",
            select: "",
            show_gene_heatmap: false,
            TissueFilter: TissueFilter,
        };
    },
    change(e) {
        this.$forceUpdate();
    },
    activated() {
        location.reload()
        this.$router.go(0)

    },
    created() {
    },
    computed: {},

    methods: {
        isShow() {
            this.show_gene_heatmap = false;
        },
        async getHeatmap() {
            try {
                this.$refs["mark"].focus();

                const response = await this.axios({
                    method: "post",
                    url: "get_heatmap/",
                    data: {
                        gene: this.inputGene,
                        tissuename: this.tissuename
                    },
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
                this.img_heatmap = response["data"];
                this.show_gene_heatmap = true;
            } catch (err) {
                this.$alert(
                    "Please check the input genes, if you are not sure, you can browse our Datasets page to see related genes.",
                    "Gene",
                    {
                        confirmButtonText: "OK",
                        callback: action => {
                            this.$message({
                                type: "info",
                                message: `action: ${action}`
                            });
                        }
                    }
                );
            }
        },

        filterHandler(value, row, column) {
            const property = column["property"];
            return row[property] === value;
        },

        fnFilterChangeInit(filter) { },

        filterChange(filterObj) {
            this.input = JSON.stringify(filterObj.Tissue[0]).replace(
                /^"(.*)"$/,
                "$1"
            );
        },

        sortChange({ prop, order }) {
            this.data_list.sort(this.compare(prop, order));
        },

        compare(propertyName, sort) {
            return function (obj1, obj2) {
                var value1 = obj1[propertyName];
                var value2 = obj2[propertyName];
                if (typeof value1 === "string" && typeof value2 === "string") {
                    const res = value1.localeCompare(value2, "zh");
                    return sort === "ascending" ? res : -res;
                } else {
                    if (value1 <= value2) {
                        return sort === "ascending" ? -1 : 1;
                    } else if (value1 > value2) {
                        return sort === "ascending" ? 1 : -1;
                    }
                }
            };
        },

        tableRowClassName({ row, rowIndex }) {
            //Put the index of each row into row
            row.index = rowIndex;
        },

        handleSizeChange(val) {
            this.currentPage = 1;
            this.currentPage = val;
            this.pageSize = val;
        },
        handleCurrentChange(val) {
            this.currentPage = val;
        },

        onRowClick(row) {
            this.DatasetName = row.Dataset;
            this.$router.push(
                "/detail/" + this.DatasetName
            );
        }


        
    }
};
</script>


<style lang="scss" scoped >
@import "@/styles/info.scss";
// .el-input {
//   width: 600px;
// }

// .el-select > .el-input {
//   width: 300px;
// }

// .input-with-select .el-input-group__prepend {
//   background-color: #fff;
// }
#statistics {
    display: flex;
    flex-direction: row;
}

h2 {
    color: #567568;
}
</style>